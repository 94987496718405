<template>
  <moe-page title="设备型号参数">
    <div class="df1 df fdc overflow-h">
      <moe-describe-list title="智能信息" :col="3">
        <moe-describe-item label="设备名称"> {{ equipment.name }} </moe-describe-item>
        <moe-describe-item label="设备类型"> {{ typeComputed }} </moe-describe-item>
        <moe-describe-item label="无线类型"> {{ wirelessComputed }} </moe-describe-item>
        <moe-describe-item label="设备图片">
          <moe-image
            :src="equipment.homeImage"
            width="100px"
            height="100px"
          />
        </moe-describe-item>
        <moe-describe-item label="通用图片">
          <moe-image
            :src="equipment.image"
            width="100px"
            height="100px"
          />
        </moe-describe-item>
      </moe-describe-list>
      <div class="df1 df overflow-h">
        <div style="min-width: 400px;height: 100%;overflow:hidden;overflow-y: auto;margin-top: 20px;">
          <moe-card>
            <template #header>
              可用参数规范
            </template>
            <div style="width: 100%;height: 100%;">
              <el-tree
                ref="equipmentTree"
                :data="TreeList"
                :props="TreeProps"
                show-checkbox
                :default-checked-keys="equipmentIds"
                node-key="key"
                check-strictly
                :empty-text="emptyText"
                accordion
                highlight-current
                @check="handleChange"
              />
            </div>
          </moe-card>
        </div>
        <div class="df1 df fdc overflow-h">
          <moe-form ref="modelParamsForm" class="auto-form" :model="modelParams" :showBack="false" :showTool="false" :defaultVerify="false" style="padding: 0;">
            <div class="df fdc" style="height: 100%;">
              <moe-table :numberShow="false" ref="paramsTable" :data="modelParams.data" :params="{ pageNum: 1 }" :mode="false">
                <el-table-column label="name" prop="name" />
                <!-- <el-table-column label="type">
                  <template slot-scope="{ row }">
                    <div class="mt-22 mb-22">{{ row.type || '-' }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="arrayType">
                  <template slot-scope="{ row }">
                    <div class="mt-22 mb-22">{{ row.arrayType || '-' }}</div>
                  </template>
                </el-table-column> -->
                <el-table-column label="key" prop="key" />
                <el-table-column label="value">
                  <template slot-scope="{ row, $index }">
                    <el-form-item class="mt-20" :prop="`data.${$index}.value`" :rules="equipmentRules.value">
                      <template v-if="row.type === 'BOOLEAN'">
                        <!-- options下拉菜单 单选 -->
                        <el-select v-if="row.options" v-model="row.value">
                          <el-option :label="item.name" v-for="(item, index) in row.options" :value="item.value" :key="index"></el-option>
                        </el-select>
                        <!-- radio单选框 -->
                        <el-radio-group v-else v-model="row.value">
                          <el-radio :label="true">开</el-radio>
                          <el-radio :label="false">关</el-radio>
                        </el-radio-group>
                      </template>

                      <template v-else-if="row.type === 'INT'">
                        <!-- options下拉菜单 单选 -->
                        <el-select v-if="row.options" v-model="row.value">
                          <el-option :label="item.name" v-for="(item, index) in row.options" :value="item.value" :key="index"></el-option>
                        </el-select>
                        <!-- 输入框 数字 -->
                        <el-input style="width: 300px;" v-model="row.value" type="number" placeholder="请输入数字" clearable />
                      </template>

                      <template v-else-if="row.type === 'STRING'">
                        <!-- options下拉菜单 单选 -->
                        <el-select v-if="row.options" v-model="row.value">
                          <el-option :label="item.name" v-for="(item, index) in row.options" :value="item.value" :key="index"></el-option>
                        </el-select>
                        <!--  文本输入框 -->
                        <el-input style="width: 300px;" v-model="row.value" type="text" placeholder="请输入" clearable />
                      </template>

                      <template v-else-if="row.type === 'ARRAY'">
                        <!-- options下拉菜单 多选 -->
                        <el-select v-if="row.options" v-model="row.value" multiple>
                          <el-option :label="item.name" v-for="(item, index) in row.options" :value="item.value" :key="index"></el-option>
                        </el-select>

                        <template v-else>
                          <div class="df aic fww">
                            <moe-tag class="mr-10 mb-10" v-for="(item, index) in row.value" :key="index" closable @close="handleClose($index, index)">{{ item }}</moe-tag>

                            <template v-if="row.showInput">
                              <el-input :ref="`saveTagInput${$index}`" style="width: 150px;" class="ml-10 mb-10" :type="inputTypeComputed(row.arrayType)" :placeholder="`请输入${row.arrayType}`" v-model="row.inputValue" size="small" @keyup.enter.native="handleInputConfirm($index)" @blur="handleInputConfirm($index)" clearable></el-input>
                            </template>
                            <div class="mb-10" v-else>
                              <el-button class="button-new-tag" size="mini" @click="handleInputShow($index)">+ 新增</el-button>
                            </div>
                          </div>
                        </template>
                      </template>
                    </el-form-item>
                  </template>
                </el-table-column>
              </moe-table>
            </div>
          </moe-form>
        </div>
      </div>

      <moe-form class="default-form" style="padding: 10px 0;">
        <template slot="tool">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :loading="paramsLoad"
            @click="submit">
            {{ paramsLoad ? '提交中' : '提交信息' }}
          </el-button>
        </template>
      </moe-form>
    </div>
  </moe-page>
</template>

<script>
export default {
  name: 'SmartEquipmentParams',
  data() {
    //设备型号参数数据校验
    const verify = this.$moe_verify.verifyForm
    return {
      equipment: {
        id: '',
        name: '',       // 设备名称
        type: '',       // 设备类型
        wireless: '',   // 无线类型
        homeImage: '',  // 首页图片
        image: '',      // 通用图片
      },
      typeList: {},
      wirelessList: {},
      TreeList: [],
      equipmentIds: [],
      TreeProps: {
        children: 'childs',
        label: 'title',
      },
      emptyText: '加载中...',
      modelParams: {
        data: [],
      },
      paramsLoad: false,
      equipmentRules: {
        value: verify.isEmpty('请完善value'),
      },
    }
  },
  computed: {
    typeComputed() {
      if (this.equipment.type === '') {
        return ''
      }

      let type = ''

      for (let key in this.typeList) {
        if (this.equipment.type === this.typeList[key]) {
          type = key
          break
        }
      }
      return type
    },
    wirelessComputed() {
      if (!this.equipment.wireless) {
        return ''
      }

      let wireless = ''

      for (let key in this.wirelessList) {
        if (this.equipment.wireless === this.wirelessList[key]) {
          wireless = key
          break
        }
      }
      return wireless
    }
  },
  methods: {
    /** 提交 */
    submit() {
      if (!this.modelParams.data.length) {
        return this.$moe_msg.warning('请添加可用参数规范');
      }
      this.$refs['modelParamsForm'].validate(() => {
        this.paramsLoad = true
        let params = {}
        if (this.modelParams.data.length) {
          this.modelParams.data.forEach(({ type, key, value }) => {
            if (type === 'STRING') {
              params[key] = value;
              return true
            }
            try {
              params[key] = JSON.parse(value);
              if (type === 'ARRAY' && !Array.isArray(params[key])) {
                params[key] = [params[key]]
              }
            } catch {
              params[key] = value;
            }
          })
          params = JSON.stringify(params);
        }
        this.$moe_api.EQUIPMENT_API.smartEquipmentAmend({
          id: this.equipment.id,
          params
        }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('修改成功');
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.paramsLoad = false
        })
      })
    },
    inputTypeComputed(arrayType) {
      if (arrayType === 'INT') {
        return 'number'
      } else {
        return 'text'
      }
    },
    /** 获取input焦点 */
    handleInputShow(index) {
      this.modelParams.data[index].showInput = true;
      this.$nextTick(() => {
        this.$refs[`saveTagInput${index}`].$refs.input.focus();
      })
    },
    /** 添加value */
    handleInputConfirm(index) {
      if (this.modelParams.data[index].inputValue) {
        this.modelParams.data[index].value.push(this.modelParams.data[index].inputValue);
      }
      this.modelParams.data[index].showInput = false;
      this.modelParams.data[index].inputValue = '';
    },
    /** 删除value */
    handleClose($index, index) {
      this.modelParams.data[$index].value.splice(index, 1)
    },
    /**
      * 监听树形菜单
      */
    handleChange(data, { checkedNodes, checkedKeys }) {
      let findIndex = checkedKeys.findIndex((key) => data.key === key);
      if (findIndex > -1) {
        /**
          * 每次选择后
          * 先清空表格数据
          * 按照左边树形从上到下排版
          */
        let data = JSON.parse(JSON.stringify(this.modelParams.data));
        this.$nextTick(() => {

          this.modelParams.data = checkedNodes.map(({ name, type, arrayType, key, options }) => {
            return {
              name,
              type,
              arrayType,
              key,
              options,
              value: type === 'ARRAY' ? [] : '',
              showInput: false,
              inputValue: '',
            }
          })

          this.modelParams.data.forEach((item) => {
            data.forEach(({ key, value }) => {
              if (item.key === key) {
                item.value = value;
              }
            })
          })
        })

      } else {
        this.modelParams.data.forEach(({ key }, index) => {
          if (data.key === key) {
            this.modelParams.data.splice(index, 1);
          }
        })
      }
    },
    /** 获取智能设备详情 */
    getequipmentDetail() {
      this.$moe_api.EQUIPMENT_API.smartEquipmentDetail({ id: this.$route.query.id }).then((data) => {
        if (data.code == 200) {
          let result = data.result;

          this.equipment = {
            id: result.id,
            name: result.name,
            type: result.type,
            wireless: result.wireless,
            homeImage: result.homeImage,
            image: result.image,
          }

          let params = ""
          if (result.params) {
            params = JSON.parse(result.params);
            for (let key in params) {
              if (typeof (params[key]) === 'object' && !Array.isArray(params[key])) {
                params[key] = JSON.stringify(params[key])
              }
              this.modelParams.data.push({
                key,
                value: params[key],
                name: '',
                type: '',
                arrayType: '',
                options: '',
                showInput: false,
                inputValue: '',
              });
              this.equipmentIds.push(key);
            }
          }

          this.getParamsSpec();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 可用参数规范 */
    getParamsSpec() {
      this.$moe_api.EQUIPMENT_API.paramsSpec({}).then((data) => {
        if (data.code === 200) {
          this.TreeList = data.result.params.map((item, index) => {
            return {
              ...item,
              title: `${index}.${item.name}${!item.key?'（类目）':''}${item.key?`-${item.key}`:''}`,
              childs: item.modelParams ? item.modelParams.map((itemChildsItem) => {
                return {
                  ...itemChildsItem,
                  title: `${itemChildsItem.name}-${itemChildsItem.key}`,
                  disabled: item.modelType === this.equipment.type ? false : true
                }
              }) : [],
              disabled: item.key ? false : true
            }
          })

          if (this.TreeList.length) {
            this.modelParams.data.forEach((item) => {
              this.TreeList.forEach(({ key, name, type, arrayType, options, childs }) => {
                if (item.key === key) {
                  item.name = name;
                  item.type = type;
                  item.arrayType = arrayType;
                  item.options = options;
                  if (item.type === 'ARRAY' && !Array.isArray(item.value)) {
                    item.value = [item.value]
                  }
                }
                if (childs && childs.length) {
                  childs.forEach(({ key, name, type, arrayType, options }) => {
                    if (item.key === key) {
                      item.name = name;
                      item.type = type;
                      item.arrayType = arrayType;
                      item.options = options;
                    }
                  })
                }
              })
            })
          }
        } else {
          this.emptyText = '加载失败';
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 可用参数 */
    getDeviceModelParams() {
      this.$moe_api.EQUIPMENT_API.getDeviceModelParams().then((data) => {
        if (data.code === 200) {
          const { type, wireless } = data.result;
          this.typeList = type;
          this.wirelessList = wireless;
        } else {
          this.$moe_msg.error(data.message)
        }
      })
    },
  },
  mounted() {
    this.getDeviceModelParams();
    this.getequipmentDetail();
  }
}
</script>